@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700);
.copyright-cont{
    width: 100%;
    height: 70px;
    background-color: #12232f;
    color: #d6d6d6;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    overflow: hidden;
    bottom: 0;
}

.footer-logo{
    position: relative;
    right:0;
}
.footer-logo img{
    width: 80px;
}
.footer-logo b{
    margin:0px 5.5px;
}

.logo-container { 
    color: white;
    width: 80%;
}

.test-border {
    border: 1px solid red;
}

.flexer {
    display: flex;
    align-items: center;
    justify-content: center;
}

h1{
    font-size: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    margin-bottom: 15px;
  }
  table{
    width:100%;
    table-layout: fixed;
  }
  .tbl-header{
    background-color: rgba(255,255,255,0.3);
   }
  .tbl-content{
    height:auto;
    /* max-height: 200px; */
    margin: 0 auto;
    width: 100%;
    margin-bottom: 100px;
    border: 1px solid rgba(255,255,255,0.3);
    /* overflow-y: scroll; */
  }

  .table-link {   
      color: white;
      font-weight: bold;
      text-decoration: underline;
  }

  .table-link:hover {
      color: darkgreen;
  }

  th{
    padding: 20px 15px;
    font-weight: 500;
    font-size: 1em;
    color: #fff;
    text-transform: uppercase;
  }
  td{
    padding: 15px;
    text-align: center;
    vertical-align:middle;
    font-weight: 300;
    font-size: 1em;
    color: #fff;
    border-bottom: solid 1px rgba(255,255,255,0.1);
  }
  
  
  /* demo styles */
  body{
    background: -webkit-gradient(linear, left top, right top, from(#25c481), to(#25b7c4));
    background: linear-gradient(to right, #25c481, #25b7c4);
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
  }
 

  section { 
      width: 50%;
      margin: 0 auto;
  }
  
  
  
  /* for custom scrollbar for webkit browser*/
  
  ::-webkit-scrollbar {
      width: 6px;
  } 
  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  } 
  ::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  }
.navbar{
    position: relative;
    width:100vw;
    top: 0;
    background: rgba(255, 255, 255, 0.824);
    border-bottom: 5px solid #01645fb0 ;
    overflow-x: hidden;
}
a:hover{
    text-decoration: none !important;
}

.logout-btn{
  float: right;
  border-radius: 100px;
  background: -webkit-gradient(linear, right top, left top, from(#08d88d), to(#0eb5a3));
  background: linear-gradient(to left, #08d88d, #0eb5a3);
  padding: 5px 20px;
  color: white;
  border: none;
  background-size: contain;
}

